@import "assets/fonts/font.css";

* {
  font-family: 'Montserrat', sans-serif !important;
}

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif !important;
  height: 100vh;
}

.MuiButton-root {
  text-transform: initial !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-top: 8rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-breathe infinite 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-breathe {
  0% {
    transform: scale(0.5);
    filter: opacity(0.25);
  }

  50% {
    transform: scale(0.75);
    filter: opacity(1);
  }

  100% {
    transform: scale(0.5);
    filter: opacity(0.25);
  }
}

.MuiTooltip-tooltip {
  max-width: 200px;
  text-align: left;
  background-color: #222f40 !important;
  color: white !important;
  white-space: pre-line;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiMenuItem-root .MuiListItemText-root .MuiTypography-root {
  max-height: 100px;
  white-space: pre-wrap;
}

.MuiInputLabel-root.Mui-focused,
.MuiInputLabel-root.Mui-focused .MuiTypography-root {
  color: #00000099 !important;
}

.MuiDialog-paper {
  border-radius: 0.75em !important;
}

.MuiDialog-paper .MuiDialogTitle-root {
  padding: 8px 16px !important;
  background-color: #2491eb;
  font-weight: 600;
  color: white;
}

input {
  font-family: 'Montserrat';
}

.MuiTableCell-head,
.MuiTableCell-body {
  font-family: 'Montserrat' !important;
}

.MuiTableCell-head {
  font-weight: 600 !important;
  color: #00000099 !important;
}

.MuiAccordionSummary-root {
  background-color: #f0f2f5;
}

.MuiAccordion-root {
  margin-top: 16px;
}

.MuiAccordion-root.Mui-expanded {
  border: 1.5px solid #222;
}

.MuiPopover-root .MuiPaper-root {
  border: 1px solid #FFBF00;
}

.MuiPopover-root .MuiPaper-root .MuiCheckbox-root .MuiSvgIcon-root {
  color: #1976D2 !important;
}

.no-padding {
  padding: 0 !important;
}